import SlimSelect from 'slim-select';

class Select {
    constructor (element) {
        this.$select = element;
        this.selectElement = null;
    }

    initialize () {
        if (this.$select) {
            this.buildCustomSelect(this.$select);
        }
    }

    buildCustomSelect ($select) {
        const search = $select.classList.contains('has--search');
        const alwaysOpen = $select.classList.contains('is--relative');

        if ($select.getAttribute('multiple') !== null) {
            if (alwaysOpen) {
                this.selectElement = new SlimSelect({
                    select: $select,
                    settings: {
                        showSearch: search,
                        closeOnSelect: false,
                        searchHighlight: true,
                        allowDeselect: true,
                        searchFocus: false,
                        openPosition: 'down',
                        alwaysOpen: true,
                        contentLocation: $select.parentNode,
                        contentPosition: 'relative'
                    }
                });
            } else {
                this.selectElement = new SlimSelect({
                    select: $select,
                    settings: {
                        showSearch: search,
                        closeOnSelect: false,
                        searchHighlight: true,
                        allowDeselect: true,
                        searchFocus: false,
                        openPosition: 'down'
                    }
                });
            }
        } else {
            if ($select.classList.contains('lang-select')) {
                this.selectElement = new SlimSelect({
                    select: $select,
                    settings: {
                        hideSelected: true,
                        showSearch: false,
                        openPosition: 'down'
                    }
                });

                $select.addEventListener('change', () => {
                    window.location.href = $select.value;
                });
            } else {
                if ($select.classList.contains('is--relative')) {
                    this.selectElement = new SlimSelect({
                        select: $select,
                        settings: {
                            showSearch: search,
                            searchHighlight: search,
                            closeOnSelect: false,
                            alwaysOpen: true,
                            allowDeselect: true,
                            hideSelected: false,
                            contentLocation: $select.parentNode,
                            contentPosition: 'relative',
                            openPosition: 'down'
                        }
                    });
                } else {
                    this.selectElement = new SlimSelect({
                        select: $select,
                        settings: {
                            showSearch: search,
                            searchHighlight: search,
                            closeOnSelect: true,
                            allowDeselect: true,
                            hideSelected: false,
                            openPosition: 'down'
                        }
                    });
                }
            }
        }

        window.addEventListener('customSelectChange', (event) => {
            if (event.detail.select === $select) {
                const optionsData = event.detail.options;
                let currentValue = this.$select.value;

                if (optionsData) {
                    Object.entries(optionsData).forEach(([key, option]) => {
                        if (option.disabled && option.value === currentValue) {
                            currentValue = 'select';
                        }
                    });

                    Object.entries(optionsData).forEach(([key, option]) => {
                        this.updateOptionState(option.value, option.disabled, option.value === currentValue);
                    });
                }
            }
        });

        window.addEventListener('reset.filter', () => {
            if (this.selectElement && !$select.classList.contains('lang-select') && !$select.classList.contains('is--currency')) {
                this.selectElement.setSelected('-1');
            }
        });

        if ($select.previousElementSibling) {
            const $label = $select.previousElementSibling;
            if ($label.classList.contains('is--hidden')) {
                $select.addEventListener('change', () => {
                    if ($select.value !== '') {
                        $label.classList.remove('is--hidden');
                    } else {
                        $label.classList.add('is--hidden');
                    }
                });
            }
        }
    }

    updateOptionState (value, disabled, select = false) {
        const $option = Array.from(this.$select.options).find(opt => opt.value === value);

        if ($option) {
            $option.disabled = disabled;

            if (select && !disabled) {
                $option.selected = true;
                this.$select.value = value;
                this.selectElement.setSelected(value);
            }
        }
    }
}

export { Select };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $selects = $context.querySelectorAll('select[data-select]');
        if ($selects.length > 0) {
            $selects.forEach((select) => {
                const newSelect = new Select(select);
                newSelect.initialize();
            });
        }
    }
});
